::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #808080;
}

body,
html {
  font-size: 13px;
  color: var(--text-color);
}

.container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.p.paginator {
  border: none;
}

.profile-picture {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

div.form-field {
  margin-bottom: 12px;
}

div.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

div.button-container button {
  margin-left: 8px;
}

.item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.data-view-item {
  width: 100%;
  padding: 10px 0px 10px 0px;

  img {
    height: 130px;
    aspect-ratio: 3/4;
    margin-right: 20px;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    div {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

.summary {
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.display-layout {
  display: grid;
  gap: 16px;
}

.field {
  display: flex;
  align-items: center;
}

.label {
  width: 100px;
}

.input-field {
  flex: 1;
  max-width: 200px;
}

.userbutton-container {
  display: flex;
  gap: 16px;
}

.usersave-button {
  background-color: green;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.p-field {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.p-d-flex {
  padding-top: 4rem;
  display: flex;
}

.p-jc-between {
  justify-content: space-between;
}

.addusercontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.addusercontainer h4 {
  flex: 1;
  margin: 0;
}

.square-button {
  border-radius: 0 !important;
}
.user-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  background-color: #dddbdb;
}

.user-icon {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.user-name {
  margin-left: 2rem;
  font-size: 3rem;
}

.user-profile {
  display: flex;
  justify-content: space-between;

  .user-details {
    display: flex;

    .basic-details {
      padding-top: 25px;
    }

    img {
      width: 200px;
      height: 200px;
      margin-right: 20px;
    }
  }

  .advanced-details {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}

.p-jc-end {
  display: flex;
  justify-content: flex-end;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.button-space {
  flex: 1;
}

.adddesigncontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.adddesigncontainer h4 {
  flex: 1;
  margin: 0;
}
.timeline-checkbox {
  margin-bottom: 10px;
}

.timeline-checkbox label {
  margin-left: 7px;
}

.label-input-wrapper1 {
  display: flex;
  flex-direction: column;
}

.label-input-wrapper1 label {
  margin-top: 1rem;
  margin-bottom: 1px;
}

.label-input-wrapper1 input {
  padding: 5px;
  border: 1px solid #ccc;
}
.datetime-inputs {
  display: flex;
  align-items: center;
}

.label-input-wrapper2 {
  display: flex;
  flex-direction: column;
}

.label-input-wrapper2 label {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.label-input-wrapper2 input {
  padding: 5px;
  border: 1px solid #ccc;
}

.custom-input {
  width: 14.5rem;
  width: 100%;
}

.remarkbutton {
  display: flex;
}

.card-content {
  font-size: 14px;
}

.timeline-header {
  margin-bottom: 2rem;
}

.addons-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}
.button-addons {
  margin-left: 4px;
}

.timeline-event {
  margin-bottom: 8px;
}

.text-secondary {
  color: var(--surface-600);
}

.p-paginator {
  border: none;
}

.p-datatable-table {
  min-width: max-content !important;
  width: 100%;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.order-table th,
.order-table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.order-table th {
  text-align: left;
}

.event-label {
  font-size: 16px;
}
.p-radio input[type="radio"] {
  width: 15.5px;
  height: 15.5px;
}

.html-box {
  height: 2.5rem;
  width: 101rem;
  margin-left: 10px;
  background-color: #fffefe;
  border: 1px solid black;
}

.align-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.remark-style {
  background: #ffecb3;
  color: #7f6003;
  border-radius: 5px;
  font-size: 14px;
}

.react-resizable-handle {
  z-index: 10;
}

.note-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px !important;
}

.to-do-board {
  position: relative;
  width: 100%;
  height: calc(100vh - 240px);
  background-color: white;
  border-radius: 10px;
}

.note-text-area {
  border: none;
  width: 100%;
  overflow: hidden;
  resize: none;
  font-size: 16px;
  padding: 10px;
  box-shadow: none;
  outline: none !important;
  box-shadow: none !important;
  background: transparent;
}

.report-header {
  display: none;
}

@media print {
  .report-header {
    display: block;
  }
}

.colored-row {
  width: 100%;
  display: block;
  height: 100%;
  padding: 10px;
}
